var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "panel-wrapper pa-0 credit-details",
      attrs: { "grid-list-lg": "", fluid: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "TabCard",
                { attrs: { title: "Credits" } },
                [
                  _vm._v(_vm._s(_vm.Credits) + "\n                    "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-subheader", { staticClass: "panel-title" }, [
                        _vm._v("Total Credits: $" + _vm._s(_vm.totalCredit))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.list
                        ? _c(
                            "v-data-table",
                            {
                              staticClass: "elevation-1",
                              attrs: {
                                loading: _vm.loading,
                                headers: _vm.headers,
                                "hide-actions": "",
                                items: _vm.list
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "items",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("td", [
                                          _vm._v(_vm._s(item.credit || "--"))
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                (
                                                  Number(
                                                    item.current_credit_info
                                                      .Withdrawable
                                                  ) +
                                                  Number(
                                                    item.current_credit_info
                                                      .Non_withdrawable
                                                  )
                                                ).toFixed(2)
                                              ) +
                                              "\n                                "
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              item.created
                                                ? _vm.formatDate(
                                                    item.created,
                                                    "MM/DD/YYYY hh:mm"
                                                  )
                                                : "--"
                                            )
                                          )
                                        ]),
                                        _c(
                                          "td",
                                          [
                                            item.invoice
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to:
                                                        "/invoice-detail/" +
                                                        item.invoice.uuid
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          item.invoice.id
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                )
                                              : _c("span", [_vm._v("--")])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "no-data",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "min-height": "192px",
                                              position: "relative"
                                            }
                                          },
                                          [
                                            _c("Spin", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.loading,
                                                  expression: "loading"
                                                }
                                              ]
                                            }),
                                            _c(
                                              "Empty",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.loading,
                                                    expression: "!loading "
                                                  }
                                                ]
                                              },
                                              [
                                                _vm.active === 0
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticStyle: {
                                                          "text-align": "center"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            There is no credit records.\n                                        "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1833402056
                              )
                            },
                            [
                              _c("v-progress-linear", {
                                attrs: { color: "blue", indeterminate: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "progress",
                                      fn: function() {
                                        return undefined
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  63074868
                                )
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.total > 0
                        ? _c(
                            "v-layout",
                            { staticClass: "mt-3" },
                            [
                              _c("v-spacer"),
                              _vm.total > 1
                                ? _c(
                                    "v-flex",
                                    [
                                      _c("v-pagination", {
                                        attrs: {
                                          disabled: _vm.loading,
                                          length: _vm.total
                                        },
                                        on: { input: _vm.getList },
                                        model: {
                                          value: _vm.page,
                                          callback: function($$v) {
                                            _vm.page = $$v
                                          },
                                          expression: "page"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }