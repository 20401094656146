<template>
    <v-container class="panel-wrapper pa-0 credit-details" grid-list-lg fluid>
        <v-layout column>
            <v-flex>
                <TabCard title="Credits">{{ Credits }}
                    <v-col cols="12">
                        <v-subheader class="panel-title">Total Credits: ${{ totalCredit }}</v-subheader>
                        <!-- <p class="mt-1">
                            Withdrawable Credits:
                            <strong class="enlight-red">${{ withdrawable }}</strong>,
                            Non-withdrawable Credits:
                            <strong class="enlight-red">${{ nonWithdrawable }}</strong>
                        </p> -->
                    </v-col>
                    <v-col cols="12">
                        <v-data-table
                                v-if="list"
                                :loading="loading"
                                :headers="headers"
                                class="elevation-1"
                                hide-actions
                                :items="list">
                            <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
                            <template v-slot:items="{item}">                                
                                <!-- <td>{{ item.credit_type ||'--'}}</td> -->
                                <td>{{ item.credit ||'--'}}</td>
                                <td>
                                    {{(Number(item.current_credit_info.Withdrawable)+Number(item.current_credit_info.Non_withdrawable)).toFixed(2)}}
                                </td>
                                <td>{{ item.created?formatDate(item.created ,'MM/DD/YYYY hh:mm'):'--' }}</td>
								<td>
									<router-link v-if="item.invoice" :to="`/invoice-detail/${item.invoice.uuid}`">
									{{item.invoice.id}}
									</router-link>
									<span v-else>--</span>
								</td>
                               <!-- <td style="text-align: right">{{ item.note ||'--'}}</td> -->
                            </template>
                            <template v-slot:no-data>
                                <div style="min-height: 192px;position: relative;">
                                    <Spin v-show="loading"></Spin>
                                    <Empty v-show="!loading ">
                                        <p v-if="active === 0" style="text-align: center">
                                            There is no credit records.
                                        </p>
                                    </Empty>
                                </div>
                            </template>
                        </v-data-table>
						<v-layout class="mt-3" v-if="total>0">
							<v-spacer></v-spacer>
							<v-flex v-if="total>1">
								<v-pagination @input="getList" :disabled="loading" v-model="page" :length="total" ></v-pagination>								
							</v-flex>
						</v-layout>
                    </v-col>
                </TabCard>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {formatDate} from '@/module/utils/date'
    import TabCard from '@/components/TabCard.vue';
    import Empty from '@/components/Empty'
    import Spin from '@/components/Spin'
    import {mapState} from 'vuex'

    let headers = [
        // {sortable: false, text: 'Credit Type', value: 'credit_type'},
        {sortable: false, text: 'Credit', value: 'credit'},
        {sortable: false, text: 'Balance', value: 'balance'},
        {sortable: false, text: 'Created', value: 'created'},		
		{sortable: false, text: 'Invoice ID', value: 'invoice.id'},
        // {sortable: false, text: 'Notes', value: 'note', align: 'right'}
    ]
    export default {
        name: 'Credits',
        components: {
            TabCard, Spin, Empty
        },
        data() {
            return {
                page: 0,
				total: 0,
                page_size: 30,                
                loading: false,
				withdrawable:0,
				nonWithdrawable:0,
                list: [],
            };
        },
        computed: {            
            totalCredit() {
                return (Number(this.withdrawable) + Number(this.nonWithdrawable)).toFixed(2);
            },
            ...mapState('client', ['clientInfo'])
        },
        methods: {
            formatDate,
			getCurrentCredit(){
				this.$http.get(`/client_credit_transactions/current_credit/`).then(results => {
					this.withdrawable=results.Withdrawable.toFixed(2);
					this.nonWithdrawable=results.Non_withdrawable.toFixed(2);
				}).catch(e => {
					this.$message.error(e.detail);
				});
			},
            getList(page = 1) {
                this.page = page;
                this.loading = true;
                this.$vuetify.goTo(0);
                this.$http.get(`/client_credit_transactions/`, {
                    params: {
                        page_size:this.page_size,
                        page: page
                    }
                }).then(( {page_total,results,page} ) => {					
                    this.list = results;                   
                    this.page = page;
					this.total = page_total;
                    this.loading = false;
                }).catch(e => {
                    this.$message.error(e.detail);
                    this.loading = false;
                });
            }
        },
        mounted() {
			this.getCurrentCredit()
            this.getList()
            this.headers = headers
        },
        created() {
            this.$SDK.track({
                pageName : 'Billing_Credits'
            })
        }
    };
</script>
<style lang="scss">
.credit-details{
	.enlight-red {
		color: orangered;
		font-size: 18px;
	}
}    
</style>
